// #a-scene {
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   position: absolute;
// }

canvas {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  pointer-events: all;
  cursor: grab;
}