#a-guides {
  top: 0;
  left: 0;
  right: 0;

  z-index: 99;
  opacity: 0.1;
  height: 100%;

  display: grid;
  display: none;

  margin: 0 4.167vw;
  position: fixed;
  pointer-events: none;

  grid-gap: 1.042vw;
  align-items: start;
  grid-template-columns: repeat(12, 1fr);

  div {
    height: 100vh;
    display: block;
    background: red;
  }
}

.a-grid {
  display: grid;
  margin-left: 4.167vw;
  margin-right: 4.167vw;
  align-items: flex-start;
  grid-column-gap: 1.042vw;
  grid-template-columns: repeat(12, 1fr);
}

// mobile
@media #{$media-580} {
  #a-guides {
    margin: 0 5.556vw;
    grid-gap: 5.556vw;
    grid-template-columns: repeat(6, 1fr);
  }

  .a-grid {
    margin-left: 5.556vw;
    margin-right: 5.556vw;
    grid-column-gap: 5.556vw;
    grid-template-columns: repeat(6, 1fr);
  }
}
