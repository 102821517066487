@font-face {
  font-family: 'Canela Text';
  src: url('./assets/fonts/CanelaText-Medium.woff2') format('woff2'), url('./assets/fonts/CanelaText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Text';
  src: url('./assets/fonts/CanelaText-Regular.woff2') format('woff2'), url('./assets/fonts/CanelaText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Text';
  src: url('./assets/fonts/CanelaText-LightItalic.woff2') format('woff2'), url('./assets/fonts/CanelaText-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Text';
  src: url('./assets/fonts/CanelaText-Light.woff2') format('woff2'), url('./assets/fonts/CanelaText-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Canela Text';
  src: url('./assets/fonts/CanelaText-ThinItalic.woff2') format('woff2'), url('./assets/fonts/CanelaText-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Bold.woff2') format('woff2'), url('./assets/fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('./assets/fonts/Lato-Regular.woff2') format('woff2'), url('./assets/fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
