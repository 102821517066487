html,
body {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: absolute;
}

.is-device {
  overflow: inherit;
  position: relative;

  body {
    overflow: hidden;
    overflow-y: scroll;
  }
}

body {
  font-family: 'Lato';
  background-color: #f6f5f1;
}

#a-app {
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  position: absolute;
  will-change: transform;
  z-index: 2;
}

.is-device {
  #a-app {
    position: relative;
  }
}

[data-ui-light] {
  top: 16vw;
  left: 0;
  bottom: 17vw;
  width: 10vw;
  position: absolute;
}

[data-animate='fade'] {
  opacity: 0;
  visibility: hidden;
}

::-moz-selection {
  color: #f6f5f1;
  background: #413780;
}

::selection {
  color: #f6f5f1;
  background: #413780;
}
