.services-heading {
  margin: auto;
  padding-top: 4.167vw;
  text-align: center;

  @media #{$media-580} {
    width: 88.889vw;
    padding: 8.333vw 5.556vw;
  }

  h2 {
    @extend .f-heading-2;
  }

  br {
    @media #{$media-580} {
      display: none;
    }
  }
}

em {
  display: inline;
}

.services-symbols {
  top: 36.458vw;
  left: -1.563vw;
  position: absolute;
  width: 97.036vw;

  @media #{$media-580} {
    top: 50vw;
    left: 0;
    width: 100vw;
  }
}

.services-book {
  position: absolute;
  width: 29.688vw;
  height: 25.691vw;
  left: 50.677vw;
  bottom: -2.604vw;

  @media #{$media-580} {
    left: 5.556vw;
    bottom: 115vw;
    width: 88.889vw;
    height: 76.922vw;
  }
}

.services-columns {
  margin-top: 11.979vw;
  margin-bottom: 11.979vw;

  h3 {
    @extend .f-heading-1;
    margin: 1.719vw 0;

    @media #{$media-580} {
      margin: 5.556vw 0 8.333vw;
    }
  }

  p {
    @extend .f-paragraph;
    width: 14.583vw;

    @media #{$media-580} {
      width: auto;
    }
  }
}

.services-service {
  grid-column: 1/12;

  @media #{$media-580} {
    text-align: center;
  }

  &:nth-child(1) {
    grid-column: 3/7;
  }

  &:nth-child(2) {
    grid-column: 6/10;
    margin-top: -2.083vw;
  }

  &:nth-child(3) {
    grid-column: 9/12;
  }

  &:nth-child(4) {
    grid-column: 6/10;
    margin-top: -3.125vw;
  }

  &:nth-child(5) {
    grid-column: 3/8;
    margin-top: -2.083vw;
  }

  &:nth-child(6) {
    grid-column: 7/12;
    margin-top: 6.771vw;
  }

  &:nth-child(7) {
    grid-column: 3/8;
    margin-top: 15.625vw;
  }

  // blog
  &:nth-child(6) {
    display: flex;
    flex-direction: column;

    @media #{$media-580} {
      text-align: left;
      padding-top: 35vw;
    }

    > * {
      &:nth-child(1) {
        order: 2;
        margin: 1.719vw 0;

        @media #{$media-580} {
          margin: 8.333vw 0 6.944vw;
        }
      }

      &:nth-child(2) {
        order: 1;
      }

      &:nth-child(3) {
        order: 3;

        p {
          @extend .f-heading-3;
          width: unset;
        }

        br {
          @media #{$media-580} {
            display: none;
          }
        }
      }
    }

    h3 {
      @extend .f-heading-0;
      margin: 0;
    }
  }

  // book
  &:nth-child(7) {
    @media #{$media-580} {
      padding-top: 100vw;
    }
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    @media #{$media-580} {
      grid-column: 1/7;
      margin: 0 0 16.667vw 0;
    }
  }
}

.svg-services {
  * {
    transform-origin: center;
    transform-box: fill-box;
  }

  @media #{$media-580} {
    display: none;
  }
}

.svg-services-mobile {
  display: none;

  * {
    transform-origin: center;
    transform-box: fill-box;
  }

  @media #{$media-580} {
    display: block;
  }
}
