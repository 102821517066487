.s-hello {
  .f-button {
    display: none;
    visibility: hidden;
    opacity: 0;

    @media #{$media-580} {
      display: flex;
      width: 41.667vw;
      height: 13.889vw;
      margin: 8.333vw auto;
    }
  }
}

.hello-heading {
  width: 36.198vw;
  margin: auto;
  padding-top: 16.667vw;
  text-align: center;
  visibility: hidden;

  @media #{$media-580} {
    width: 88.889vw;
    padding: 50vw 2.778vw 0;
  }

  h2 {
    @extend .f-heading-2;
  }

  br {
    @media #{$media-580} {
      display: none;
    }
  }
}

.hello-graphic {
  width: 42.969vw;
  height: 42.969vw;
  visibility: hidden;
  margin: 10.417vw auto 7.813vw;

  @media #{$media-580} {
    width: 88.889vw;
    height: 88.889vw;
    margin: 23.611vw auto;
  }
}

.hello-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.146vw;
  height: 2.135vw;
  z-index: 2;
  transform: translate(-50%, -50%);

  @media #{$media-580} {
    width: 2.5vw;
    height: 4.722vw;
  }
}

.hello-hero {
  margin: auto;
  text-align: center;

  @media #{$media-580} {
    width: 80.556vw;
  }

  h3 {
    @extend .f-heading-0;
  }

  em {
    margin-left: -0.417vw;
  }
}

.hello-divider {
  width: 41.875vw;
  height: 3.483vw;
  margin: 4.167vw auto 0;

  @media #{$media-580} {
    width: 100%;
    height: auto;
    margin: 8.333vw auto 0;
  }

  line {
    @media #{$media-580} {
      display: none;
    }
  }
}
