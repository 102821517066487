#a-cta {
  position: fixed;
  width: 9.375vw;
  height: 3.385vw;
  right: 4.167vw;
  top: 3.49vw;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(white, black);
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  @media #{$media-580} {
    display: none;
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    line-height: 3.125vw;
    justify-content: center;
    white-space: nowrap;
    pointer-events: none;
    z-index: 2;
  }

  .circle {
    top: 0;
    left: 0;
    position: absolute;
    width: 10vw;
    height: 10vw;
    display: block;
    will-change: transform;
    z-index: 1;
    margin: 0;
    pointer-events: none;
  }
}

.active {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  background: #4b418a;
  backface-visibility: hidden;
  will-change: transform;
}
