.s-stories {
  margin: 13.021vw 0;

  @media #{$media-580} {
    margin: 20.833vw 0;
  }
}

.stories-symbols {
  width: 77.621vw;
  height: 55.787vw;
  top: 0.521vw;
  left: 10vw;
  position: absolute;
  z-index: 2;
  pointer-events: none;

  @media #{$media-580} {
    width: auto;
    height: auto;
    top: -27.778vw;
    left: 0;
  }
}

.svg-stories {
  width: 100%;

  * {
    transform-origin: center;
    transform-box: fill-box;
  }

  @media #{$media-580} {
    display: none;
  }
}

.svg-stories-mobile {
  display: none;
  overflow: visible;

  * {
    transform-origin: center;
    transform-box: fill-box;
  }

  @media #{$media-580} {
    width: 100%;
    display: block;
  }
}

.stories-shape {
  height: 58.596vw;
  width: 95.986vw;

  @media #{$media-580} {
    width: 306vw;
    height: auto;
    transform: translateX(-152vw);
  }
}

.stories-indicator {
  top: 18vw;
  left: 34.635vw;
  position: absolute;
  z-index: 2;
  visibility: hidden;

  @media #{$media-580} {
    left: 50%;
    transform: translateX(-50%);
  }

  .f-title {
    color: #fff;
    display: flex;
    justify-content: center;
  }
}

.stories-current {
  position: relative;
  width: 0.781vw;
  margin-left: 0.26vw;

  @media #{$media-580} {
    width: 4.167vw;
    margin-left: 1.389vw;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.stories-total {
  opacity: 0.5;
}

.stories-slide {
  top: 0;
  left: 34.635vw;
  width: 45.313vw;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media #{$media-580} {
    left: 5.556vw;
    right: 5.556vw;
    width: auto;
    height: 160vw;
    text-align: center;
  }

  span {
    opacity: 0.4;
  }

  h2,
  p {
    opacity: 0;
    visibility: hidden;
  }

  h2 {
    @extend .f-heading-1;
    font-weight: 300;
    color: #fff;
    margin: 3.125vw 0;
    white-space: nowrap;

    em {
      display: inline;
    }

    @media #{$media-580} {
      margin: 0 0 8.333vw;
      white-space: normal;
    }
  }

  p {
    @extend .f-heading-2;
    font-weight: normal;
    color: #fff;
  }
}

.stories-nav {
  z-index: 3;

  @media #{$media-580} {
    left: 50%;
    right: 0;
    bottom: 25vw;
    width: 61.111vw;
    height: 27.778vw;
    position: absolute;
    transform: translateX(-50%);
  }
}

.svg-arrow,
.svg-arrow {
  top: 50%;
  width: 5.208vw;
  height: 5.208vw;
  position: absolute;
  transform: translateY(-50%);
  overflow: hidden;
  cursor: pointer;
  visibility: hidden;

  @media #{$media-580} {
    width: 27.778vw;
    height: 27.778vw;
  }

  &:nth-child(1) {
    left: 24.74vw;

    @media #{$media-580} {
      left: 0;
    }
  }

  &:nth-child(2) {
    right: 10.417vw;
    transform: translateY(-50%) rotate(180deg);

    @media #{$media-580} {
      right: 0;
    }
  }
}
