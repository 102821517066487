.header-logo {
  position: absolute;
  left: 50%;
  top: 4.167vw;
  width: 6.771vw;
  display: block;
  overflow: hidden;
  transform: translateX(-50%);
  text-indent: -100vw;
  z-index: 9;
  visibility: hidden;
  padding: 0 0.625vw 0 0;
  pointer-events: none;

  @media #{$media-580} {
    width: 25.833vw;
    top: 10vw;
    padding: 0 2.778vw 0 0;
  }

  .svg-star {
    top: 0;
    right: 0;
    width: 0.521vw;
    height: 0.521vw;
    position: absolute;
    visibility: hidden;

    @media #{$media-580} {
      width: 1.944vw;
      height: 1.944vw;
    }

    path {
      fill: #4a3780;
    }
  }
}

h1 {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
