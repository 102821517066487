#a-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 4.167vw;
  z-index: 99;
  --color-active: #F9442C;

  @media #{$media-580} {
    display: none;
  }
}

.nav-buttons {
  .f-nav {
    display: inline-flex;
    position: relative;
    height: 1.25vw;
    line-height: 1.25vw;
    text-decoration: none;
    visibility: hidden;

    div {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #4b418a;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94), background 1000ms cubic-bezier(0.1, 0.005, 0, 1);
      will-change: transform;
    }

    &.ui-light {
      div {
        background: white;
      }
    }

    &:hover {
      div {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}

.nav-li {
  display: block;
  height: 1.25vw;
  line-height: 1.2vw;
  overflow: hidden;

  &.is-active {
    div {
      background: var(--color-active);
    }

    .ui-light {
      div {
        background: var(--color-active);
      }
    }
  }
}

.nav-active {
  position: absolute;
  top: 0.417vw;
  left: -0.781vw;
  width: 0.495vw;
  height: 0.47vw;
  opacity: 0;
}
