.s-about {
  z-index: 2;
}

.about-heading {
  top: 0;
  left: 19.271vw;
  position: absolute;

  @media #{$media-580} {
    left: auto;
    position: relative;
    margin: 0 5.556vw;
  }

  h2 {
    @extend .f-heading-0;
    font-weight: 400;
  }
}

.about-symbols {
  width: 20.044vw;
  height: 42.891vw;
  position: absolute;
  left: 20.417vw;
  bottom: -5.99vw;

  @media #{$media-580} {
    left: auto;
    bottom: 5.833vw;
    position: relative;
    width: 51.017vw;
    height: 109.167vw;
    margin: 0 auto -6.5vw;
  }
}

.about-content {
  margin: 0 10.156vw 0 57.292vw;

  @media #{$media-580} {
    margin: 0 5.556vw;
  }
}

.about-block {
  margin: 5.833vw 0;

  @media #{$media-580} {
    margin: 13.889vw 0;
  }

  .f-title {
    margin-bottom: 1.563vw;

    @media #{$media-580} {
      margin-bottom: 8.333vw;
    }
  }

  // meet & greet
  &:nth-child(1) {
    p {
      @extend .f-heading-2;
      font-weight: normal;
      margin-bottom: 3.125vw;

      @media #{$media-580} {
        margin-bottom: 8.333vw;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  // name dropping
  &:nth-child(2) {
    p {
      @extend .f-heading-3;
      margin-bottom: 3.125vw;

      &:last-child {
        margin: 0;
      }
    }

    br {
      @media #{$media-580} {
        display: none;
      }
    }

    a {
      @extend .f-underline;

      &:before {
        transform: scale(1);
      }

      &:hover {
        &:before {
          transform: scale(0);
        }
      }
    }

    .f-line {
      overflow: hidden;
    }
  }
}

// me at a glance
.about-value {
  .f-list {
    display: flex;
  }

  strong {
    &:nth-child(1) {
      width: 2.188vw;

      @media #{$media-580} {
        width: 13.056vw;
      }
    }
    &:nth-child(2) {
      width: 3.542vw;

      @media #{$media-580} {
        width: 18.333vw;
      }
    }
  }
}
