.f-heading-0 {
  font-family: 'Canela Text';
  font-weight: 300;
  font-size: 8.333vw;
  line-height: 8.333vw;
  letter-spacing: -0.06em;
  color: #4b418a;

  @media #{$media-580} {
    font-size: 22.222vw;
    line-height: 22.222vw;
  }

  em {
    font-weight: 100;
    font-style: italic;
  }

  .f-line {
    height: 8.854vw;
    line-height: 5.313vw;

    @media #{$media-580} {
      height: 23.611vw;
      line-height: 13.889vw;
    }
  }
}

.f-heading-1 {
  font-family: 'Canela Text';
  font-style: normal;
  font-weight: normal;
  font-size: 4.167vw;
  line-height: 4.167vw;
  letter-spacing: -0.04em;
  font-feature-settings: 'salt' on;
  color: #413780;

  @media #{$media-580} {
    font-size: 13.333vw;
    line-height: 13.333vw;
  }

  em {
    font-weight: 100;
    font-style: italic;
  }

  .f-line {
    height: 4.479vw;
    line-height: 2.865vw;

    @media #{$media-580} {
      height: 14.722vw;
      line-height: 10.278vw;
    }
  }
}

.f-heading-2 {
  font-family: 'Canela Text';
  font-style: normal;
  font-weight: 500;
  font-size: 2.083vw;
  line-height: 2.5vw;
  letter-spacing: -0.03em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ss08' on;
  color: #413780;

  @media #{$media-580} {
    font-size: 6.111vw;
    line-height: 8.333vw;
  }

  .f-line {
    height: 2.604vw;
    line-height: 1.927vw;

    @media #{$media-580} {
      height: 8.333vw;
      line-height: 6.111vw;
    }
  }
}

.f-heading-3 {
  font-family: 'Canela Text';
  font-style: normal;
  font-weight: normal;
  font-size: 1.667vw;
  line-height: 2.083vw;
  letter-spacing: -0.03em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'case' on, 'ss01' on, 'ss02' on, 'ss04' on, 'ss05' on, 'ss06' on,
    'ss07' on, 'ss08' on;
  color: #413780;

  @media #{$media-580} {
    font-size: 6.667vw;
    line-height: 8.889vw;
  }

  .f-word {
    height: 2.083vw;
    line-height: 1.667vw;
  }
}

.f-heading-4 {
  font-family: 'Canela Text';
  font-style: normal;
  font-weight: normal;
  font-size: 1.25vw;
  line-height: 2.083vw;
  letter-spacing: -0.03em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ss08' on;
  color: #413780;

  @media #{$media-580} {
    font-size: 6.667vw;
    line-height: 11.111vw;
  }
}

.f-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 0.729vw;
  line-height: 1.25vw;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #413780;

  @media #{$media-580} {
    font-size: 3.889vw;
    line-height: 6.667vw;
  }
}

.f-nav {
  font-family: 'Canela Text';
  font-style: normal;
  font-weight: 500;
  font-size: 0.938vw;
  line-height: 0;
  letter-spacing: -0.02em;
  color: #413780;
  transition: color 1000ms cubic-bezier(0.1, 0.005, 0, 1);

  &.ui-light {
    color: white;
  }
}

.f-paragraph {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 0.833vw;
  line-height: 1.25vw;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ss08' on;
  color: #4b418a;

  @media #{$media-580} {
    font-size: 4.444vw;
    line-height: 6.667vw;
  }
}

.f-list {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 0.833vw;
  line-height: 1.667vw;
  letter-spacing: -0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on, 'ss08' on;
  color: #4b418a;

  @media #{$media-580} {
    font-size: 4.444vw;
    line-height: 8.889vw;
  }
}

.f-button {
  font-family: 'Canela Text';
  font-style: normal;
  font-weight: 500;
  font-size: 0.938vw;
  line-height: 2.604vw;
  letter-spacing: -0.03em;
  color: #413780;
  border: 1px solid rgba(65, 55, 128, 0.2);
  border-radius: 4.688vw;
  display: flex;
  justify-content: center;
  background-color: #f6f5f1;

  @media #{$media-580} {
    font-size: 4.444vw;
    line-height: 12.5vw;
    border-radius: 6.944vw;
  }

  em {
    font-weight: 300;
    font-style: italic;
  }
}

.f-line-parent {
  overflow: hidden;
  position: relative;
}

.f-underline {
  position: relative;
  text-decoration: none;

  &:before {
    left: 0;
    bottom: 0;

    width: 100%;
    height: 1px;

    content: '';
    position: absolute;

    background: #4b418a;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    will-change: transform;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
}
