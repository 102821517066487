.s-contact {
  z-index: 2;
  overflow: hidden;
  pointer-events: none;
  border-top: 1px solid rgba(75, 65, 138, 0.2);

  p,
  a {
    @extend .f-paragraph;
  }

  a {
    @extend .f-underline;
    pointer-events: all;
  }

  li {
    line-height: 1.667vw;

    @media #{$media-580} {
      line-height: 8.889vw;
    }
  }

  .f-heading-4 {
    margin-bottom: 0.521vw;

    @media #{$media-580} {
      margin-bottom: 4.167vw;
    }
  }
}

.svg-scroll {
  width: 5.208vw;
  height: 5.208vw;
  right: 4.167vw;
  bottom: 2.083vw;
  position: absolute;
  pointer-events: all;
  overflow: hidden;

  @media #{$media-580} {
    width: 25.833vw;
    height: 25.833vw;
    bottom: 16.667vw;
    right: 5.556vw;
  }
}

.contact-symbols {
  left: -1.563vw;
  bottom: -2.344vw;
  position: absolute;
  width: 69.905vw;
  height: 17.552vw;
  visibility: hidden;

  @media #{$media-580} {
    left: -4vw;
    bottom: -5vw;
    position: absolute;
    width: 163.839vw;
    height: 40.994vw;
    visibility: visible;
  }
}

.is-safari,
.is-firefox {
  .contact-symbols {
    visibility: visible;
  }
}

.contact-heading {
  white-space: nowrap;
  grid-column: 7/13;
  margin: 10.417vw 0 4.167vw;

  @media #{$media-580} {
    grid-column: 1/7;
    margin: 22.222vw 0 8.333vw;
  }

  h2 {
    @extend .f-heading-0;

    white-space: nowrap;
  }

  br {
    @media #{$media-580} {
      display: none;
    }
  }

  em {
    &::after {
      content: '.';
      color: #f9442c;
    }
  }
}

.contact-word {
  grid-column: 2/5;

  @media #{$media-580} {
    grid-column: 1/7;
    margin-bottom: 11.111vw;
  }

  p {
    width: 18.75vw;

    @media #{$media-580} {
      width: auto;
    }
  }
}

.contact-column {
  flex-shrink: 0;
  width: 15.208vw;

  @media #{$media-580} {
    width: 50%;
  }

  &:nth-child(1) {
    @media #{$media-580} {
      display: none;
    }
  }
}

.contact-nav {
  display: flex;
  grid-column: 7/12;
  margin: 0 0 8.333vw;

  @media #{$media-580} {
    grid-column: 1/7;
    margin: 0 0 13.889vw;
  }
}

.contact-bottom {
  display: flex;
  grid-column: 7/12;
  margin: 0 0 14.583vw;

  @media #{$media-580} {
    grid-column: 1/7;
    flex-direction: column;
    margin: 0 0 55.556vw;
  }

  p {
    &:nth-child(1) {
      margin-right: 2.344vw;

      @media #{$media-580} {
        margin-right: 0;
      }
    }
  }
}
